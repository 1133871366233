import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';

import {CustomBreakPointsProvider} from 'app/layout/custom-breakpoints';
import {HorizontalLayoutModule} from 'app/layout/horizontal/horizontal-layout.module';
import {VerticalLayoutModule} from "./vertical/vertical-layout.module";

@NgModule({
    imports: [
        FlexLayoutModule.withConfig({disableDefaultBps: true}),
        HorizontalLayoutModule,
        VerticalLayoutModule
    ],
    providers: [CustomBreakPointsProvider],
    exports: [HorizontalLayoutModule, VerticalLayoutModule]
})
export class LayoutModule {
}
