import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatarNumeroCelular'
})
export class FormatarNumeroCelularPipe implements PipeTransform {
    transform(value: any): any {
        if (value) {
            // Remove caracteres não numéricos
            const numeroLimpo = value.toString().replace(/\D/g, '');

            // Verifica se o número possui 11 dígitos (com DDD)
            if (numeroLimpo.length === 11) {
                return `(${numeroLimpo.substring(0, 2)}) ${numeroLimpo.substring(2, 3)} ${numeroLimpo.substring(3, 7)}-${numeroLimpo.substring(7)}`;
            }
        }

        // Retorna o valor original se não puder ser formatado
        return value;
    }
}
