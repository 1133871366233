import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AutenticacaoService} from 'app/shared/clients/user-client';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private autenticacaoService: AutenticacaoService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let primaryToken = JSON.parse(localStorage.getItem('primary_token'))
        let token = JSON.parse(localStorage.getItem('token'))

        if (token) {
            localStorage.removeItem('primary_token');
        }

        if (token || primaryToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token ?? primaryToken}`
                }
            });
        }

        return next.handle(request);
    }
}
