import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {AutenticacaoService} from "../clients/user-client";

@Injectable({
    providedIn: 'root'
})
export class PermissaoService {
    constructor(
        private _router: Router,
        private autenticacaoService: AutenticacaoService
    ) {
    }

    temPermissao(permission: string): boolean {
        const usuarioAtual: any = this.autenticacaoService.currentUserValue;

        if (usuarioAtual && permission
            && usuarioAtual.acl.funcionalidades.some(role => role.id == permission)
        ) {
            return true;
        }

        return false;
    }

    isPerfil(perfil: string): boolean {
        const usuarioAtual: any = this.autenticacaoService.currentUserValue;

        if (usuarioAtual && usuarioAtual.permissao == perfil
        ) {
            return true;
        }

        return false;
    }
}
