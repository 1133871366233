<!-- Bookmarked Icons -->
<div class="">
    <b *ngIf="currentUser != undefined && currentUser.permissao == 'ADMIN_CREDENCIADO'">Administrador</b>
    <b *ngIf="currentUser != undefined && currentUser.permissao == 'GERENCIA_CREDENCIADO'">Gerência</b>
    <b *ngIf="currentUser != undefined && currentUser.permissao == 'ADMIN_PLATAFORMA'">Administrador Geral</b>
    <b *ngIf="currentUser != undefined && currentUser.permissao == 'ADMIN_AUDITOR'">Auditor</b>
    <b *ngIf="currentUser != undefined && currentUser.permissao == 'SAC_CREDENCIADO'">SAC Credenciado</b>
    <b *ngIf="currentUser != undefined && currentUser.permissao == 'SAC_PLATAFORMA'">SAC Plataforma</b>
</div>

