import {Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as Waves from 'node-waves';

import {CoreMenuService} from '@core/components/core-menu/core-menu.service';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {CoreConfigService} from '@core/services/config.service';
import {CoreLoadingScreenService} from '@core/services/loading-screen.service';
import {CoreTranslationService} from '@core/services/translation.service';

import {menuParceiro} from 'app/shared/menu/menuParceiro';
import {menuAdministradorPlataforma} from "./shared/menu/menuAdministradorPlataforma";
import {data} from "autoprefixer";
import {CoreMenu} from "../@core/types";
import {AutenticacaoService} from "./shared/clients/user-client";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    menu: any;
    coreConfig: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _title: Title,
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        private _coreMenuService: CoreMenuService,
        private _translateService: TranslateService,
        public _coreConfigService: CoreConfigService,
        private _coreSidebarService: CoreSidebarService,
        private _coreLoadingScreenService: CoreLoadingScreenService,
        private autenticacaoService: AutenticacaoService
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        Waves.init();
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
            this._elementRef.nativeElement.classList.remove(
                'vertical-layout',
                'vertical-menu-modern',
                'horizontal-layout',
                'horizontal-menu'
            );
            // Add class based on config options
            if (this.coreConfig.layout.type === 'vertical') {
                this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
            } else if (this.coreConfig.layout.type === 'horizontal') {
                this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
            }

            // Remove default classes first
            this._elementRef.nativeElement.classList.remove(
                'navbar-floating',
                'navbar-static',
                'navbar-sticky',
                'navbar-hidden'
            );

            // Add class based on config options
            if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
                this._elementRef.nativeElement.classList.add('navbar-static');
            } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
                this._elementRef.nativeElement.classList.add('navbar-sticky');
            } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
                this._elementRef.nativeElement.classList.add('navbar-floating');
            } else {
                this._elementRef.nativeElement.classList.add('navbar-hidden');
            }

            this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

            // Add class based on config options
            if (this.coreConfig.layout.footer.type === 'footer-sticky') {
                this._elementRef.nativeElement.classList.add('footer-fixed');
            } else if (this.coreConfig.layout.footer.type === 'footer-static') {
                this._elementRef.nativeElement.classList.add('footer-static');
            } else {
                this._elementRef.nativeElement.classList.add('footer-hidden');
            }

            if (
                this.coreConfig.layout.menu.hidden &&
                this.coreConfig.layout.navbar.hidden &&
                this.coreConfig.layout.footer.hidden
            ) {
                this._elementRef.nativeElement.classList.add('blank-page');
                // ! Fix: Transition issue while coming from blank page
                this._renderer.setAttribute(
                    this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
                    'style',
                    'transition:none'
                );
            } else {
                this._elementRef.nativeElement.classList.remove('blank-page');
                // ! Fix: Transition issue while coming from blank page
                setTimeout(() => {
                    this._renderer.setAttribute(
                        this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
                        'style',
                        'transition:300ms ease all'
                    );
                }, 0);
                // If navbar hidden
                if (this.coreConfig.layout.navbar.hidden) {
                    this._elementRef.nativeElement.classList.add('navbar-hidden');
                }
                // Menu (Vertical menu hidden)
                if (this.coreConfig.layout.menu.hidden) {
                    this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
                } else {
                    this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
                }
                // Footer
                if (this.coreConfig.layout.footer.hidden) {
                    this._elementRef.nativeElement.classList.add('footer-hidden');
                }
            }

            // Skin Class (Adding to body as it requires highest priority)
            if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
                this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
                this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
            }
        });
        this._title.setTitle(this.coreConfig.app.appTitle);

        this.autenticacaoService.currentUser.subscribe((user: any) => {
            let menu: CoreMenu[] = user?.acl?.funcionalidades;
            this._coreMenuService.unregister('main');
            this._coreMenuService.register('main', menu);
            this._coreMenuService.setCurrentMenu('main');
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
