import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from 'environments/environment';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class UsuarioService {

    constructor(private http: HttpClient) {
    }

    public verificarUsuarioPorCPF(cpf: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}backoffice-gerencial/usuario/verificar-por-cpf/${cpf}`, {});
    }

    salvar(usuario: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}backoffice-gerencial/usuario`, usuario);
    }
}
