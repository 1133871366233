import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'cpfCnpj'
})
export class CpfCnpjPipe implements PipeTransform {
    transform(value: any): any {
        if (value && value.length === 11) {
            // Formata CPF: 999.999.999-99
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (value && value.length === 14) {
            // Formata CNPJ: 99.999.999/9999-99
            return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        } else {
            // Retorna o valor original
            return value;
        }
    }
}
