import {map} from 'rxjs/operators';
import {User} from 'app/shared/models';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class AutenticacaoService {
    public currentUser: Observable<User>;
    public currentToken: Observable<String>;
    private currentUserSubject: BehaviorSubject<User>;
    private currentTokenSubject: BehaviorSubject<String>;

    constructor(
        private _http: HttpClient,
        private router: Router,
    ) {
        this.currentTokenSubject = new BehaviorSubject<String>(JSON.parse(localStorage.getItem('token')));
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));

        this.currentUser = this.currentUserSubject.asObservable();
        this.currentToken = this.currentTokenSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public setCurrentUserValue(user: User) {
        this.currentUserSubject.next(user);
    }

    public get tokenValue(): any {
        return this.currentTokenSubject.value;
    }

    public setTokenValue(token: any) {
        this.currentTokenSubject.next(token);
    }

    login(email: string, password: string) {
        return this._http
            .post<any>(`${environment.apiUrl}autenticacao/login`, {email, password})
            .pipe(
                map(data => {
                    if (data && data.access_token) {
                        localStorage.setItem('currentUser', JSON.stringify(data.user));
                        localStorage.setItem('token', JSON.stringify(data.access_token));
                        this.currentUserSubject.next(data.user);
                        return data;
                    }

                    return data;
                })
            );
    }

    selecionarPerfil(role) {
        return this._http.post<any>(`${environment.apiUrl}autenticacao/select-profile`, role);
    }

    obterCredenciados() {
        return this._http.get<any>(`${environment.apiUrl}autenticacao/obter-credenciados`);
    }

    me() {
        return this._http.get<any>(`${environment.apiUrl}autenticacao/me`);
    }

    sair() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
    }
}
