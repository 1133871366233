<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
    <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
    <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->

